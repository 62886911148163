$viewPortLg: 1180px;
$viewPortSm: 1000px;
$viewPortSmm: 860px;
$viewPortXs: 767px;
$viewPortXss: 640px;
$viewPortXsss: 460px;
$viewPortXssss: 590px;

/* view port: 1180px
====================================*/
@media (max-width: $viewPortLg){
	.intro-text{
		font-size: 28px;
	}
	.body-text{
		font-size: 15px;
	}
	.top-nav ul {
		> li{
			padding-left:15px;
			a{
				font-size: 16px;
				&:hover{
					font-size: 16px;
				}
			}
		}.active{
			> a{
				font-size: 16px;
			}
		}
	}


	.hdr-callWidget{
		ul{
			width:70%;
		}
	}

	 
	.slideshow .slide-des {
		h1{
			font-size:28px; 
		}
		
	}
	.left-widgets{
		display: none;
	}
	.content-wrap .content-col1 p{
		font-size: 30px;
	}
	.content-wrap .brands-wrap ul{
		text-align: center;
		width: 100%;
		li{
			float: none;
			width: calc(100%/5);
			display: inline-block;
			img{
				max-width: 100%;
				height:auto;
			}

			}
	}

	.services-wrap{
		padding: 60px 0;
		.service-box{
			h3{
				font-size: 20px;
			}
			
			.service-des{
				p{
					font-size: 16px;
				}

				a{
					font-size: 13px;
					display: block;
					
					&:hover{
						font-size: 13px;
					}
				}
			}
		}
	}

	.gray-container{
		h1{
			font-size: 30px;
		}
		.expertise-content{
			.expertise-box{
				a{
					font-size: 25px;
					&:hover{
						font-size: 25px;
					}
				}
			}
		}
	}

	.expertise-des{
		.content-lg{
			p{
				font-size:28px;
			}
		}

		.content-indent{
			p{
				font-size: 15px;
			}
		}

		.expertise-sidebar{
			p{
				strong{
					font-size: 15px;
				}

				a{
					font-size: 15px;
					&:hover{
						font-size: 15px;
					}
				}
			}
		}
	}
	.projects-wrap{
		.pro-title{
			padding: 40px 0;
			h2{
				font-size: 30px;
			}
		}

		.projects-indent{
			.project-tabs{
				padding: 50px 0;
				.btn{
					font-size: 30px;
					&:hover{
						font-size: 30px;
					}
				}
			}
		}

		.projects-indent2{
			 .project-box{
			 	h4{
			 		font-size: 20px;
			 	}
			 }
		}
	}
	 
}


/* view port: 1000px
====================================*/
@media (max-width: $viewPortSm){
	.hdr-callWidget{
		ul{
			width: 100%;
		}
	}

	.top-nav ul {
		> li{
			padding-left:10px;
			a{
				font-size: 15px;
				&:hover{
					font-size: 15px;
				}
			}
		}.active{
			> a{
				font-size: 15px;
			}
		}
	}

	.slideshow .slide-des {
		h1{
			font-size:22px; 
		}
		
	}

	.content-wrap{
		padding: 45px 0;
		 .content-col1{
		 	p{
		 		font-size: 22px;
		 	}
		 }
	}

	.footer-wrap{
		.copyright{
			font-size: 15px;
		}

		.footer-links{
		ul{			
			padding:0 0 0 42px;			
			li{				
				font-size:15px;	
				a{
					font-size:16px;					
					 
				}
			}
		}
 
		}
	}



}



/* view port: 860px
====================================*/
@media (max-width: $viewPortSmm){
	.btn-blue{
		font-size: 14px;
		&:hover{
			font-size: 14px;
		}
	}

	.btn-yellow{
		font-size: 14px;
		&:hover{
			font-size: 14px;
		}
	}

	.top-nav ul {
		> li{
			padding-left:7px;
			a{
				font-size: 13px;
				&:hover{
					font-size: 13px;
				}
			}
		}.active{
			> a{
				font-size: 13px;
			}
		}
	}

	.hdr-callWidget{
		
		ul{
			li{
				strong{
					font-size: 15px;
				}
				a{
					font-size: 14px;
					&:hover{
						font-size: 14px;
					}
				}
			}
		}
	}

}


/* view port: 767px
====================================*/
@media (max-width: $viewPortXs){
	.hdr-callWidget{
		display: none;
	}

	//testimonial

	.testimonial{
		padding: 60px 0;
		img{
			max-width: 100%;
		}
		p.quote{
			font-size: 18px;
		}
		p.quote-attribute{
			font-size: 12px;
		}
	}

	//contact form

	.contact-box{
		.details-holder{
			h3:first-child{
				margin-top:30px;
			}
			ul{
				list-style: none;
				font-size: 18px;
				a{
					text-decoration: none;
					color: #000000;

				}
			}
		}
	}
	.top-nav{
		background:rgba(0, 0, 0, 0.8);
		padding:18px;
		margin-top: 7px;
		display: none;		
		z-index: 999999;
		position: absolute;
		width: 100%;
		overflow-y: scroll;
		ul{
			text-align: center;
			li{
				display: block;
				padding: 0;
				font-size: 20px;
				margin: 0;
				border:0;			 
				border-top: 2px solid #fff;
				ul{
					display: block;
					position: relative;
					background: none;
					top:0;
					width: 100%;
					border-top: 2px solid #fff;
					li{
						list-style: none;
						text-align:center;
						a{
							font-size: 15px;
							&:hover{
								font-size: 15px;
							}
						}
					}
				}
				a{
					font-size: 18px;
					color: #fff;
					display: block;
					padding: 10px 12px;
					border:0;					

					&:hover{
						font-size: 18px;
						color: #fff
					}
				}

			}.active{
				a{
					font-weight: normal;
					font-size:inherit;
					font-family: inherit;

					+ ul{
						li{
							a{
								font-size: 15px;
								&:hover{
									font-size: 15px;
								}
							}
						}
					}
				}
			}

			li:first-child{
				border:0;
			}
		}
	}

	body{
		margin-top:88px;
	}
	.header-wrap{		
		z-index: 9999;
		position: fixed;
		top: 0;
		width:100%;
		background: #fff;				
		.col-md-9{
			padding: 0;
		}
		 .logo-mobile{
				display: block;
		 }

		 .logo-desktop{
				display: none;
		 }

		.mobile-hdr{
			display: block;
			position: absolute;
			z-index: 600;
			top: 30px;
			right: 18px;
			ul{
				width: 100%;
				margin: 0;
				padding: 0;				
				text-align: right;
				li{
					list-style: none;
					display: inline-block;
					font-size: 25px;
					color: #6f014a;
					vertical-align: middle;
					padding-left: 18px;
					a{
						font-size: 35px;
						color: #6f014a;
					}

					.mobile-nav{
						width: 40px;
						height: auto;
						border: 0;
						box-shadow: none;
						background: none;
						span{
							width: 100%;
							display: block;
							height: 6px;
							background: #6f014a;
							margin: 4px 0;
							border-radius: 3px;
						}
					}
				}
			}

		}


	}

	.header-wrap.active{
		padding: 5px 0;
		background: #fff;
		position: fixed;
		z-index:9999;
		width: 100%;
		.logo{
			width:136px;
			height: auto;
		}
		.mobile-hdr{
			top:12px;
		}

	}

	.services-wrap{
		padding: 15px 0 25px 0;
		.service-box {
			padding: 18px 0 0 0;
			.service-des{
				padding: 0;
				p{
					padding: 12px 0 15px 0;
				}
			}
		}


	}

	.content-col2 img{
		width: 100%;
		height: auto;
		margin-bottom: 25px;
	}

	.slideshow{
		.slide-des{
			top: 0;

			h1{
				font-size:20px; 
			}

			.slide-button{
				padding:8px 0 0 0;
			}
		}
	}

	.btn-blue{
		font-size: 12px;
		&:hover{
			font-size: 12px;
		}
	}

	.btn-yellow{
		font-size: 12px;
		&:hover{
			font-size: 12px;
		}
	}





.gray-container{
		h1{
			font-size: 26px;
		}
		.expertise-content{
			.expertise-box{
				a{
					font-size: 18px;
					&:hover{
						font-size: 18px;
					}
				}
				.expertise-icon{
					width: 150px;
					height: 100px;
					background-size:cover;
					background-position: 0 -110px;
				}
			}
		}
}
	.intro-text{
		font-size: 20px;
	}
	.body-text{
		font-size: 14px;
	}

 .expertise-des{
		.content-lg{
			p{
				font-size:20px;
			}
		}

		.content-indent{
			p, li{
				font-size: 14px;
			}
		}

		.expertise-sidebar{
			p{
				strong{
					font-size: 14px;
				}

				a{
					font-size: 14px;
					&:hover{
						font-size: 14px;
					}
				}
			}

			.office-col{
				img{
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.projects-wrap{
		.pro-title{
			padding: 25px 0;
			h2{
				font-size: 22px;
			}
		}

		.projects-indent{
			padding: 0 0 26px 0;
			.project-tabs{
				padding: 25px 0;
				.btn{
					font-size: 20px;
					&:hover{
						font-size: 20px;
					}
				}
			}
		}

		.projects-indent2{
			 .project-box{
			 	h4{
			 		font-size: 17px;
			 	}
			 }
		}
	}

	
	.slideshow{

		.slide-mob{
				display: block !important;
			}

		.slide-desk{
			display: none !important;
		}

	}

}



/* view port: 640px
====================================*/
@media (max-width: $viewPortXss){
	.header-wrap{
		
		.mobile-hdr{		
			top: 20px;		
		}
		 
	}

	.slideshow{
		.container{
			padding:0;
		}
		 .slide-des{
		 	display: none;
		 }
	}

	.content-wrap{
		padding: 25px 0 0 0;
		.content-col1{
			p{
				font-size: 18px;
			}
		}

		.content-col2{
			padding: 25px 0 0 0;
			p{
				font-size: 15px;
				line-height: 135%;
			}
		}

		.brands-wrap{
			padding: 15px 0;
			ul{
				li{
					width: calc(100%/4);
				}
			}

		}
	}
	
}

/* view port: 590px
====================================*/
@media (max-width: $viewPortXssss){
	.expertise-content{
		.our-expertise{
			.col-xs-4{
				width: 100%;
				height: auto;
				margin-top: 25px;
			}
		}
	}

	.gray-container{
		h1{
			padding-bottom: 12px;
		}
	}
}

/* view port: 460px
====================================*/
@media (max-width: $viewPortXsss){
	.footer-wrap{
		.copyright{
			font-size: 13px;
		}
		.footer-links{
			ul{
				display: block;
				float: none;
				margin: 0;
				padding:0;
				li{
					font-size: 15px;
					padding: 3px 0;
					a{
						font-size: 15px;
						&:hover{
							font-size: 15px;
						}
					}
				}
			}

		}

	}

	.project-tabs{
		.col-xs-6{
			width: 100%;
			float: none;
			margin:6px 0;
			.btn{
				display: block;
			}
		}
	}

	.expertise-des{
		.content-indent{
			padding:12px 0 0 0;
		}
		.col-xs-6{
			float: none;
			width: 100%;
			margin: 10px 0;
		}
	}

	.projects-wrap{
		.pro-title{
			h2{
				font-size: 18px;
			}
		}
	}

	.gray-container{
		h1{
			font-size: 20px;
		}
	}
}